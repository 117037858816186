html,
body,
#root {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: "Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
  print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
}
